/* #divToQR{
  display:none;
} */

#header {
  height: 100vh;
  /*background-image: url("/images/background-header.png");*/
  background-position: center;
  background-size: cover;
}
.fa-style {
  font-size: 20px;
  color: #ffba47;
}
.container-landing {
  margin-right: 100px;
  margin-left: 100px;
}
.logo {
  margin-top: 30px;
  width: 200px;
}

.header-text {
  max-width: 450px;
  margin-top: 140px;
}
h1 {
  font-size: 34px;
}
.square {
  height: 12px;
  width: 12px;
  display: inline-block;
  background-color: #ffba47;
  margin: 15px 0;
}
/* #ffba47; */

.common-btn {
  padding: 18px 40px;
  background: transparent;
  outline: none;
  border: 2px solid #ffba47;
  font-weight: bold;
  cursor: pointer;
}
.bg-color {
  background: #ffba47;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #000 !important;
}
.text-center {
  text-align: center;
}
.landing p {
  font-size: 15px;
  line-height: 18px;
  color: #777;
}
.header-text button {
  margin-top: 20px;
  margin-bottom: 60px;
}
.line-1 {
  width: 15px;
  height: 15px;
  background: #ffba47;
  display: inline-block;
}
.line-2 {
  width: 80px;
  height: 1px;
  background: #ffba47;
  display: inline-block;
}
.line-3 {
  width: 60px;
  height: 1px;
  background: #ffba47;
  display: inline-block;
}
.line {
  line-height: 8px;
}

#sideNav {
  width: 250px;
  height: 100vh;
  position: fixed;
  right: -250px;
  top: 0;
  background: #ffba47;
  z-index: 2;
  transition: 0.5s;
}

#sideNav ul li {
  list-style: none;
  margin: 50px 20px;
}
#sideNav ul li a {
  text-decoration: none;
  color: #fff;
}
#menuBtn {
  width: 50px;
  position: fixed;
  right: 65px;
  top: 35px;
  z-index: 2;
  cursor: pointer;
}

/* about */
#about,
#offer {
  padding: 100px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.about-left-col {
  flex-basis: 50%;
}

.about-left-col img {
  width: 100%;
}
.about-right-col {
  flex-basis: 50%;
  text-align: right;
}
.about-text {
  max-width: 500px;
  margin-right: 100px;
  display: inline-block;
}

.about-text h2 {
  margin: 50px 0 10px;
  font-size: 28px;
  font-style: italic;
}

.about-text h3 {
  font-size: 20px;
  font-style: italic;
  color: #797979;
}

/* Features */
#features {
  padding-top: 50px;
  padding-bottom: 50px;
}

#features h1 {
  padding-bottom: 50px;
  font-size: 35px;
  font-style: italic;
}
.feature-row {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.feature-col {
  flex-basis: 25%;
  text-align: center;
}
.feature-col img {
  width: 100px;
}
.feature-col h4 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 400;
}
.feature-btn {
  margin: 80px auto 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.feature-btn .line {
  text-align: right;
  display: inline-block;
  margin-right: 25px;
}

/* courses */
#courses {
  padding: 100px 0;
}
.course-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.course-right-col {
  flex-basis: 50%;
}
.course-right-col img {
  width: 100%;
}
.course-left-col {
  flex-basis: 50%;
}
.course-text {
  max-width: 350px;
}
.course-text button {
  margin: 30px 0;
}
.feature-list {
  padding: 10px;
}
.course-left-col h5 {
  font-size: 16px;
  padding: 5px;
  color: #777;
  font-weight: 600;
}
/* offer */

#offer button {
  margin: 30px 0;
}

/* counter */

#counter {
  padding-top: 50px;
  padding-bottom: 50px;
}

#counter h1 {
  font-size: 30px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 10px;
}
#counter p {
  font-size: 20px;
  font-style: italic;
  text-align: center;
}
.counter-row {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.counter-col {
  flex-basis: 25%;
  text-align: center;
}
.counter-box {
  background: #fff;
  width: 175px;
  height: 175px;
  border-radius: 10px;
  text-align: center;
  -webkit-box-shadow: 0px 15px 88px -37px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 15px 88px -37px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 15px 88px -37px rgba(0, 0, 0, 0.17);
  overflow: hidden;
  margin: 10px;
  text-align: center;
}

/* contact */
.contact-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.contact-left-col,
.contact-right-col {
  flex-basis: 50%;
}
.contact-right-col img {
  width: 100%;
}
#contact form {
  max-width: 450px;
  margin: 30px 0;
}

#contact form input {
  width: 100%;
  padding: 12px 10px;
  margin-bottom: 5px;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  border: 2px solid #ffba47;
}
.btn-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.btn-box button {
  flex-basis: 48%;
  padding: 18px 0;
}
/* footer */
#footer {
  padding: 50px 0 30px;
}
hr {
  width: 100%;
  border: 0;
  border-top: 1px solid #ffba47;
}

.footer-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-left-col {
  flex-basis: 50%;
  margin-top: 60px;
}

.footer-right-col {
  flex-basis: 35%;
}
.footer-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.link-title h4 {
  color: #ffba47;
  margin-bottom: 20px;
}

.link-title small {
  font-size: 13px;
  color: #777;
}
.footer-info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-logo img {
  width: 100px;
}
.footer-logo button {
  padding: 12px 38px;
  margin-top: 20px;
  font-size: 10px;
}

.copyright-text,
.footer-logo {
  flex-basis: 40%;
}

.copyright-text small {
  font-size: 12px;
  color: #777;
}
/* social icons */
.social-icons {
  width: 50px;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.social-icons img {
  display: block;
  width: 20px;
  margin: 0 auto 20px;
  cursor: pointer;
}
.attribute {
  margin-left: 10px;
  font-size: 8px;
}

/* nearby */

#nearby h1 {
  padding-bottom: 50px;
  font-size: 35px;
  font-style: italic;
}

.card-body h2 {
  font-size: 20px;
  line-height: 1rem;
  margin-bottom: 8px;
  color: #636363;
}
.card-body h2 a {
  color: #636363;
}
.featured-listings {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.restaurant-card {
  background: #fff;
  width: 320px;
  height: 300px;
  border-radius: 10px;
  text-align: center;
  -webkit-box-shadow: 0px 15px 88px -37px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 15px 88px -37px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 15px 88px -37px rgba(0, 0, 0, 0.17);
  overflow: hidden;
  margin: 10px;
}
.card-header {
  height: 50%;
  background-size: cover;
  position: relative;
}
.favorite-btn {
  width: 50px;
  height: 50px;
  color: #fff;
  border-radius: 50%;
  position: relative;
  top: 124px;
  left: 24px;
  border: 2px solid #fff;
  background: #f22f59;
  padding-top: 15px;
  text-align: center;
  vertical-align: center;
  transition: 0.3s;
}
.favorite-btn:hover {
  background: #ff2252;
  cursor: pointer;
}
.card-body {
  font-family: "Lato", "Arial", sans-serif;
}
.rating ul {
  padding: 10px;
}
.rating li {
  display: inline;
  color: #efa127;
}
.rating li:hover {
  color: #ffa517;
  cursor: pointer;
}
a {
  text-decoration: none;
}

.featured-listings .km-away {
  color: #ffba47;
  padding: 10px;
}
@media only screen and (max-width: 763px) {
  #header {
    background-image: none;
  }
  .container-landing {
    margin-right: 50px;
    margin-left: 50px;
  }
  .logo {
    margin-top: 27px;
    width: 70px;
  }

  .header-text {
    margin-top: 100px;
  }
  h1 {
    font-size: 25px;
  }
  #menuBtn {
    width: 40px;
    right: 50px;
  }
  .common-btn {
    padding: 10px 16px;
  }
  .social-icons img {
    width: 14px;
    margin: 15px auto;
  }
  .about-left-col,
  .about-right-col {
    flex-basis: 100%;
  }
  .about-text {
    margin: 50px 50px 0;
    display: inline-block;
  }
  .about-text h2 {
    font-size: 18px;
  }
  .feature-col {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
  .course-left-col {
    flex-basis: 100%;
  }
  .course-right-col {
    flex-basis: 100%;
    margin-top: 70px;
  }
  .contact-left-col,
  .contact-right-col {
    flex-basis: 100%;
  }
  .contact-right-col {
    margin-top: 50px;
  }
  .footer-left-col,
  .footer-right-col {
    flex-basis: 100%;
  }
  .link-title {
    flex-basis: 50%;
    margin-bottom: 30px;
  }
  .footer-info {
    align-items: center;
    justify-content: center;
  }
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background: #ffffff;
  /* background-image:url('');
  opacity: 0.6; */
}

.my-container .my-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  opacity: 0.2;
}
.my-content {
  z-index: 2;
  position: relative;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.invoice {
  background: #fff;
  padding: 20px;
}

.invoice-company {
  font-size: 20px;
}

.invoice-header {
  margin: 0 -20px;
  background: #f0f3f4;
  padding: 20px;
}

.invoice-date,
.invoice-from,
.invoice-to {
  display: table-cell;
  width: 1%;
}

.invoice-from,
.invoice-to {
  padding-right: 20px;
}

.invoice-date .date,
.invoice-from strong,
.invoice-to strong {
  font-size: 16px;
  font-weight: 600;
}

.invoice-date {
  text-align: right;
  padding-left: 20px;
}

.invoice-price {
  background: #f0f3f4;
  display: table;
  width: 100%;
}

.invoice-price .invoice-price-left,
.invoice-price .invoice-price-right {
  display: table-cell;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  position: relative;
  vertical-align: middle;
}

.invoice-price .invoice-price-left .sub-price {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px;
}

.invoice-price small {
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.invoice-price .invoice-price-row {
  display: table;
  float: left;
}

.invoice-price .invoice-price-right {
  width: 25%;
  background: #2d353c;
  color: #fff;
  font-size: 28px;
  text-align: right;
  vertical-align: bottom;
  font-weight: 300;
}

.invoice-price .invoice-price-right small {
  display: block;
  opacity: 0.6;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
}

.invoice-footer {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 10px;
}

.invoice-note {
  color: #999;
  margin-top: 80px;
  font-size: 85%;
}

.invoice > div:not(.invoice-footer) {
  margin-bottom: 20px;
}

.btn.btn-white,
.btn.btn-white.disabled,
.btn.btn-white.disabled:focus,
.btn.btn-white.disabled:hover,
.btn.btn-white[disabled],
.btn.btn-white[disabled]:focus,
.btn.btn-white[disabled]:hover {
  color: #2d353c;
  background: #fff;
  border-color: #d9dfe3;
}

.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.modal-body {
  position: relative;
  padding: 0px;
}
.close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}
body {
  overflow: inherit;
  padding-right: inherit !important;
}

figure {
  width: 300px;
  background-repeat: no-repeat;
}

figure:hover img {
  opacity: 0;
}
.home-font {
  font-size: 18px;
}

.float {
  position: fixed;
  /* width:60px;
height:60px; */
  top: 10px;
  right: 10px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
